import { ContactStyles } from "./styled"

const SPContact = () => {
  return (
    <ContactStyles>
      <div className="innerWrap">
        <img src="/img/carbon_phone-filled.png" alt="phone" />
        <div>{process.env.REACT_APP_PROJECT_TITLE}</div>
        <div>{process.env.REACT_APP_PROJECT_ADDRESS}</div>
        <div>{process.env.REACT_APP_PROJECT_ADDRESS_2}</div>
        <div className="tel_fax">
          <a href={`tel:+${process.env.REACT_APP_PROJECT_TEL}`}>TEL：{process.env.REACT_APP_PROJECT_TEL}</a>
        </div>
        <div className="tel_fax">
          <a href="#">FAX：{process.env.REACT_APP_COMPANY_FAX}</a>
        </div>
      </div>
    </ContactStyles>
  )
}

export default SPContact;
